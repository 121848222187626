.accordion-container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 100%;
	max-width: 1000px;
	margin: 0 auto;
	padding: 10px 10px 10px 10px;
}

.container h1 {
	text-align: center;
	margin-bottom: 30px;
	font-weight: 500;
}

.container h2 {
	font-weight: 500;
}

.accordion {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: auto;

	@include from(l){
		width: 100%;
	}
}

.accordion .a-container {
	display: flex;
	flex-direction: column;
	width: 100%;
	padding-bottom: 5px;
	border: 2px solid var(--color-first);
	margin-bottom: 7px;
}

.accordion .a-container .a-btn {
	margin: 0;
	position: relative;
	padding: 8px 26px;
	// width: 100%;
	color: var(--color-background);
	text-transform: uppercase;
	font-weight: 400;
	display: block;
	font-weight: 500;
	background-color: transparent;
	cursor: pointer;
	transition: all 0.3s ease-in-out;
	word-break: break-word;
	box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.15), 0 10px 10px -5px rgba(0, 0, 0, 0.1) !important;
}

// .accordion .a-container .a-btn p {
// 	width: 200px;
// }

.accordion .a-container .a-btn span {
	display: block;
	position: absolute;
	height: 14px;
	width: 14px;
	right: 15px;
	top: 13px;
}

.accordion .a-container .a-btn span:after {
	content: "";
	width: 14px;
	height: 3px;
	border-radius: 2px;
	background-color: #fff;
	position: absolute;
	top: 6px;
}

.accordion .a-container .a-btn span:before {
	content: "";
	width: 14px;
	height: 3px;
	border-radius: 2px;
	background-color: #fff;
	position: absolute;
	top: 6px;
	transform: rotate(90deg);
	transition: all 0.3s ease-in-out;
}

.accordion .a-container .a-panel {
	width: 100%;
	color: var(--color-background);
	transition: all 0.2s ease-in-out;
	opacity: 0;
	height: auto;
	max-height: 0;
	overflow: hidden;
	padding: 0px 10px;
}

.accordion .a-container.active .a-btn {
	color: var(--color-background);
	background-color: var(--color-first);
}

.accordion .a-container.active .a-btn span::before {
	transform: rotate(0deg);
}

.accordion .a-container.active .a-panel {
	padding: 15px 10px 10px 10px;
	opacity: 1;
	max-height: 100%;
	background-color: var(--color-container);
}
