/// Transforma un valor en pixeles a em.
/// @group   functions
/// @author  Alvaro Felipe
/// @param   {number} $el - el valor en pixeles que se desea convertir.
/// @param   {number} $context - el valor en pixeles del contenedor.
/// @return  {number} el tamaño transformado en em.
@function em($el,$context:16px) {
  @if type_of($el) == number and unit($el) == 'px' {
    @return ($el / $context) * 1em;
  } @else {
    @error 'Function em() requires a px unit as first argument / La función em() requiere una unidad de pixeles como primer argumento (el segundo es opcional)'
  }
}

/// Transforma un valor en pixeles a rem.
/// @group   functions
/// @author  Alvaro Felipe
/// @param   {number} $el - el valor en pixeles que se desea convertir.
/// @return  {number} el tamaño transformado en rem.
@function rem($el) {
  @if type_of($el) == number and unit($el) == 'px' {
    @return ($el / 16px) * 1rem;
  } @else {
    @error 'Function rem() requires a px unit as argument / La funcion rem() requiere una unidad de pixeles como argumento';
  }
}
