.btn {
	border: none;
    border-radius: 5px;
	color: var(--color-body);
	font-weight: bold;
    padding: 4px 8px;
	font-size: var(--h1-font-size);
	cursor: pointer;
	text-transform: uppercase;
	text-align: center;
	
	&__primary {
		background-color: var(--color-first);
		font-weight: 500;
		color: var(--color-background);
		height: 42px;
		padding: 0 8px;
		font-size: 26px;
		
		@include from(l) {
			&:hover {
				color:  var(--color-background);
				background-color: var(--color-first-dark);
				border: 2px solid var(--color-third);
			}
		}
		
	}
	
	&__secondary {
		background-color: var(--color-second);
		color: var(--color-background );
		font-weight: 400;
		font-size: 17px;
		
		&:active {
			background-color: transparent;
		}
		
		@include from(l) {
			&:hover {
				background-color: var(--color-first);
			}
		}
	}
}
