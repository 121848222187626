// SEPARACIONES
// Use las siguientes clases para crear separaciones verticales armoniosas
// Las clases cancel-l- cancelan el margin bottom del hermano anterior

.l-block,
.cancel-l-block,
.l-section,
.cancel-l-section,
.l-big-section,
.cancel-l-big-section {
  margin-top : 0;
}

// Bloque
.l-block {
  margin-bottom : var(--vertical-block-space);
}

.cancel-l-block {
  margin-top : calc(var(--vertical-block-space) * -1);
}

// Sección
.l-section {
  margin-bottom : calc(var(--vertical-block-space) * 2);
}

.cancel-l-section {
  margin-top : calc(var(--vertical-block-space) * -2);
}

// Big section
.l-big-section {
  margin-bottom : calc(var(--vertical-block-space) * 4);
}

.cancel-l-big-section {
  margin-top : calc(var(--vertical-block-space) * -4);
}

// sticky footer
body {
  display        : flex;
  flex-direction : column;
  min-height     : 100vh;
}

.main-footer {
  margin-top : auto;
}

// Distribución vertical de contenido
// Puede sobreescribirlas usando las clases de arriba

// Encabezados
h1, h2, h3, h4, h5, h6 {
  margin-top    : 0;
  margin-bottom : var(--vertical-content-space);
}

h1 {
  margin-bottom : calc(var(--vertical-content-space) * 1.5);
}

// Separación entre secciones de contenido
// La norma recomienda encerrar cada sección en una etiqueta section
// Pero en la práctica es dificil conseguirlo siempre así que la presencia
// De un titulo indica el cambio de sección
p,
video,
blockquote,
article,
section,
form,
figure,
iframe,
ul,
ol,
pre,
hr,
dl,
address,
table {
  margin-top    : 0;
  margin-bottom : calc(var(--vertical-content-space) * 1.5);

  + h2 {
    padding-top : calc(var(--vertical-content-space) * 1.5);
  }

  + h3 {
    padding-top : var(--vertical-content-space);
  }
}

// Eliminar separaciones

ul ul,
ol ol,
ul ol,
ol ul {
  margin-bottom: 0;
}

// Crea paddings y margenes
@each $size in $sizes {
  @include from($size) {
    // Crea cuatro niveles de separacion
    @for $i from 0 through 5 {
      // Unidad de separación actual
      $unit : $l-unit * $i;
      // Valida el cero para evitar devolver 0rem como unidad
      @if $i == 0 {
        $unit : 0;
      }
      // Valida el 5 para devolver nueva clase y unidad
      @if $i == 5 {
        $i : "05";
        $unit: $l-unit * 0.5;
      }

      // Padding top
      .#{$size}-pt-#{$i} {
        padding-top : $unit;
      }
      // Padding left
      .#{$size}-pl-#{$i} {
        padding-left : $unit;
      }
      // Padding bottom
      .#{$size}-pb-#{$i} {
        padding-bottom : $unit;
      }
      // Padding right
      .#{$size}-pr-#{$i} {
        padding-right : $unit;
      }
      // Padding vertical
      .#{$size}-py-#{$i} {
        padding-top    : $unit;
        padding-bottom : $unit;
      }
      // Padding horizontal
      .#{$size}-px-#{$i} {
        padding-left  : $unit;
        padding-right : $unit;
      }
      // Padding total
      .#{$size}-pxy-#{$i} {
        padding : $unit;
      }
      // Margin right
      .#{$size}-mr-#{$i} {
        margin-right : $unit;
      }
      // Margin bottom
      .#{$size}-mb-#{$i} {
        margin-bottom : $unit;
      }
      // Margin bottom (negativo)
      .#{$size}-mt-#{$i} {
        margin-top : $unit * -1;
      }
      // Margin top (negativo)
      .#{$size}-ml-#{$i} {
        margin-left : $unit * -1;
      }
    }
  }
}
