// Configuraciones
$dev              : false;
$dev-hover        : false;
$max-width        : 1200px;
$l-unit           : .5rem;
$gap              : $l-unit * 4;  // 2rem
$legacy           : false;
$container        : ed-container;
$item             : ed-item;
$grid-container   : ed-grid;
$grid-item        : -cols-;
$max-grid-columns : 12;
$fractions : 1,2,3,4,5,6,7,8,9,10;

$breakpoints 		:
	(
		s	: 0,
		m	: 640px,
		l	: 960px,
		lg	: 1280px,
		xl	: 1600px
	);

@import "~ed-grid/ed-grid";
// Configuraciones
$fractions : 1,2,3,4,5,6,7,8,9,10;
$breakpoints 		: 
	(
		s	: 0,
		m	: 640px,
		l	: 960px,
		lg	: 1280px,
		xl	: 1600px
	);
@import "./ed-grid/ed-grid";
