.rules {
	max-width: 1280px;
	width: 90%;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
	padding: 1rem 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	&__h1 {
		color: var(--color-first);
		text-transform: uppercase;
	}

	&__img {
		text-align: center;
		width: 180px;
		// height: 30px;
		margin-bottom: 13px;
	}

	&__container {
		padding: 20px;
		background-color: var(--color-container);
		margin-bottom: 40px;
		color: var(--color-background);
		text-align: left;
		margin: 0;
		width: 100%;
	}
	
	&__list {
		color: var(--color-background);
		margin: 2rem 0;
	}
	&__list--ul {
		padding: 0;
		gap: 0.5rem;
	}

	&__list--li {
		list-style: none;
	}
	
	&__list--a {
		text-decoration: underline;
		text-transform: uppercase;
	}

	&__container--slider {
		display: flex;
		flex-direction: column;
	}

	&__container--slider-ul {
		margin: 0;
		padding: 0;
		list-style: none;
		margin-bottom: 0.5rem;
	}

	&__container--slider-li1 {
		background-color: var(--color-first);
		color: var(--color-background);
		padding: 0.5rem 1rem;
		border: 0.96px solid var(--color-first);
		width: 100%;
		text-align: center;
		text-transform: uppercase;

		@include from(l) {
			width: 20%;
		}
	}
	
	&__container--slider-li2 {
		padding: 1rem 1rem;
		border: 0.96px solid var(--color-first);
		color: var(--color-background);
		font-weight: 300;
		width: 100%;
		text-align: center;
		
		@include from(l) {
			width: 80%;
			text-align: left;
			padding: 0.5rem 1rem;
		}
	}

	&__container-div {
		max-width: 970px;
		margin-left: auto;
		margin-right: auto;
	}

	&__container--ul {
		@include from(l) {
			display: flex;
			flex-direction: row;
			list-style: none;
		}
	}
}



